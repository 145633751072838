const initialState = {
  name: '',
  email: '',
  phone: '',
  address_from: '',
  zip_from: '',
  address_to: '',
  zip_to: '',
  distance: '',
  note: '',
  movers: 0,
  trucks: 1,
  estimated_cost: 0,
  rate: 0,
  extra: null,
  include: null,
  av_time1: 0,
  av_time2: 0,
  job_id: null,
  job_status: 0,
  hash_id: null,
  packing: [],
  inventory: { final: [], estimated: [] },
  required_pickup: '',
  coi_approved: true,
  full_packing_rate: 0,
  credit_rate: 0,
  company_credit_rate: 0,
  travel_money: 0,
  total_packing: 0,
  job_fees: [],
  job_charges: [],
  job_discounts: [],
  manual_charge: 0,
  manual_discount: 0,
  contract_total: 0,
  addendum_total: 0,
  tips: 0,
  estimated_hours: 0,
  documents: [],
};

export { initialState };
