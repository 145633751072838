import { useEffect } from 'react';
import { useJobList } from 'hooks/query/useJobs';
import { useAuth } from 'context/auth';

const useFetchData = (type, params) => {
  const { start_date, end_date } = params;

  if (start_date && !end_date) {
    delete params.start_date;
    delete params.end_date;
  }

  const { user } = useAuth();
  const { data: jobs, refetch: onRefreshJobList } = useJobList({ ...params, type });

  useEffect(() => {
    const listener = (data) => {
      if (data.user_id === 0 || data.user_id === user?.id) onRefreshJobList();
    };

    window.socket.on('job-list-refresh', listener);

    return () => {
      window.socket.off('job-list-refresh', listener);
    };
  }, [onRefreshJobList, user]);

  useEffect(() => {
    const listener = (data) => {
      if (type === 'lead') {
        if (data.user_id === 0 || data.user_id === user?.id) {
          onRefreshJobList();
        }
      }
    };
    window.socket.on('new_lead', listener);

    return () => {
      window.socket.off('new_lead', listener);
    };
  }, [onRefreshJobList, user, type]);

  return { jobs, onRefreshJobList };
};

export { useFetchData };
