export const reminderTypeOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
];

export const packingRateOptions = [
  {
    label: 'Full packing rate',
    value: 'true',
  },
  {
    label: 'On demand',
    value: 'on_demand',
  },
  {
    label: 'Customer packing',
    value: 'customer',
  },
];

export const referralOptions = [
  {
    value: 1,
    label: 'Google',
  },
  {
    value: 2,
    label: 'Angies list',
  },
  {
    value: 3,
    label: 'YELP',
  },
  {
    value: 4,
    label: 'Returning',
  },
  {
    value: 5,
    label: 'Referral',
  },
];

export const forbiddenTypes = ['lead', 'followUp', 'sold', 'lost', 'presale', 'postsale', 'ongoing', 'completed', 'new_message'];

export const communicationsTab = {
  coi: 'coi',
  claim: 'claim',
  reminder: 'reminder',
  default: 'default',
  mail: 'mail',
  call: 'call',
  sms: 'sms',
  note: 'note',
  invoices: 'invoices',
};

export const statusTypesOptions = [
  { value: 1, label: 'Pending' },
  { value: 2, label: 'Done' },
];

export const jobFiltersOptions = {
  1: 'Assigned',
  2: 'Booked',
  3: 'Confirmed',
  4: 'In Progress',
  5: 'Delivered',
  6: 'Completed',
  8: 'Visual',
  9: 'Quoted',
  10: 'Sold',
  11: 'Rescheduled',
  12: 'Reassigned',
  13: 'In Transit',
  14: 'IN Storage',
  15: 'Off Loaded',
  16: 'OUT Storage',
  17: 'Unconfirmed',
  18: 'Canceled',
  19: 'Deleted',
  20: 'Office to Pickup',
  22: 'Lost',
  23: 'Dropped',
  24: 'Waiting for Delivery',
};

export const laborTypeOptions = [
  {
    value: 'default',
    label: 'Moving',
  },
  {
    value: 'same_property',
    label: 'Move within a same property',
  },
  {
    value: 'unloading',
    label: 'Unloading Only',
  },
  {
    value: 'packing',
    label: 'Packing Only',
  },
];

export const notesOptions = [
  {
    value: 1,
    label: 'From',
  },
  {
    value: 2,
    label: 'To',
  },
];

export const periodOptions = [
  // { value: 'all', label: 'All Jobs' },
  { value: 'future', label: 'Future' },
  { value: 'past', label: 'Past' },
  { value: 'today', label: 'Today' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'last_week', label: 'Last Week' },
  { value: 'last_two_weeks', label: 'Last 2 Weeks' },
  { value: 'last_thirty_days', label: 'Last 30 Days' },
  { value: 'this_mont', label: 'This Month' },
  { value: 'last_month', label: 'Last Month' },
  { value: 'this_year', label: 'This Year' },
  { value: 'last_year', label: 'Last Year' },
];

export const followUpSortOptions = [
  { value: 'followUp', label: 'Follow up' },
  { value: 'followUp-start_date', label: 'Start Date' },
  {
    value: 'followUp-received_date',
    label: 'Received Date',
  },
];

export const rateTypeOptions = [
  {
    value: 1,
    label: 'Hourly',
  },
  {
    value: 2,
    label: 'Flat',
  },
];

export const paymentTypeOptions = [
  {
    value: 1,
    label: 'Cash',
  },
  {
    value: 2,
    label: 'Credit',
  },
];

export const generalModalNotesTabsOptions = [
  { value: 'agent_notes', label: 'Agent Notes' },
  { value: 'internal_notes', label: 'Internal Notes' },
  { value: 'customer_notes', label: 'Customer Notes' },
];

export const generalModalSettingsTabsOptions = [
  { value: 'tasks', label: 'Tasks' },
  { value: 'claims', label: 'Claims' },
  { value: 'pdf', label: 'Pdf Header' },
  { value: 'quote', label: 'Quote Header' },
  { value: 'reminders', label: 'Reminders' },
  { value: 'sms_reminders', label: 'Sms Reminders' },
];

export const calcTypeOptions = [
  { value: 'per_cf', label: 'Per CF' },
  { value: 'per_unit', label: 'Per Unit' },
];
